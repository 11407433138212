export default class FinancialFiltersSelection {
    private readonly deliveryTeamsKey: string = 'filtersSelectionFinancial.deliveryTeams';
    private readonly deliveryTypesKey: string = 'filtersSelectionFinancial.deliveryTypes';
    private readonly overBudgetKey: string = 'filtersSelectionFinancial.overBudget';

    public setDeliveryTeams(deliveryTeams: Array<string>): void {
        localStorage.setItem(this.deliveryTeamsKey, JSON.stringify(deliveryTeams));
    }

    public getDeliveryTeams(): Array<any> {
        const deliveryTeamsResult = localStorage.getItem(this.deliveryTeamsKey);
        if (!deliveryTeamsResult) return [];
        return JSON.parse(deliveryTeamsResult);
    }

    public setDeliveryTypes(deliveryTypes: Array<string>): void {
        localStorage.setItem(this.deliveryTypesKey, JSON.stringify(deliveryTypes));
    }

    public getDeliveryTypes(): Array<any> {
        const deliveryTypesResult = localStorage.getItem(this.deliveryTypesKey);
        if (!deliveryTypesResult) return [];
        return JSON.parse(deliveryTypesResult);
    }

    public setOverBudget(overBudget: boolean): void {
        localStorage.setItem(this.overBudgetKey, JSON.stringify(overBudget));
    }

    public getOverBudget(): boolean {
        const overBudgetResult = localStorage.getItem(this.overBudgetKey);
        if (!overBudgetResult) return false;
        return JSON.parse(overBudgetResult);
    }
}
