












































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { actions } from '@/store/types';

import {
    IFinancialBenefitsUseCaseDto,
    IStatusDto,
    Status
} from '@/service-proxies/service-proxies.g';

import moment from 'moment';
import { DeliveryTeam } from '@/models';
import BenefitsFiltersSelection from '@/utils/local-storage/benefits-filters-selection';
import Currency from '@/utils/helpers/currency';

const benefitsFilterSelection = new BenefitsFiltersSelection();

const BUDGET_TYPES = {
    VALUE_ADD: 'valueAdd'
};

const PHASES = {
    EXPECTED: 'expected',
    IN_CREATION: 'inCreation',
    DELIVERED: 'delivered',
    REALIZED: 'realized'
}

const PHASE_TABS = {
    REALIZED: 'Realized',
    EXPECTED: 'Expected',
    IN_CREATION: 'In Creation',
    DELIVERED: 'Delivered'
}

const SUBVIEW = {
    ALL_STAGES: 'all-stages',
    EXPECTED: 'expected',
    IN_CREATION: 'in-creation',
    DELIVERED: 'delivered'
}

const VIEW = {
    COSTS: 'costs',
    BENEFITS: 'benefits',
}

const EMPTY_CELL = 'EMPTY_CELL';

const BENEFITS_FILTERS = {
    STATUS: 'status',
    DELIVERY_ORG: 'delivery-org',
    END_DATE:'endDate',
    START_DATE:'startDate',
    REALIZED: 'realized',
}

const FILTER_PARAMS = {
    STAGE: 'stage',
    REALIZED: 'realized',
    TERM: 'term',
    DELIVERY_ORG: 'deliveryOrganization',
    STATUS: 'status',
    START_DATE:'startDate',
    END_DATE:'endDate'
}

@Component({
    name: 'financial-benefits'
})

export default class FinancialBenefits extends Vue {

    $store: any;

    private filterByRealized = false;
    private benefitsDataLoaded = false;
    private statisticsDataLoaded = false;
    private view: any = VIEW.BENEFITS;
    private subview: any = SUBVIEW.ALL_STAGES;

    private filterByDeliveryOrg: string[] = [];
    private filterByStatus: string[] = [];
    private filterByStartDate: string | null = null;
    private filterByEndDate: string | null = null;

    private allStagesBenefitsReports: IFinancialBenefitsUseCaseDto[] = [];

    private statusIdColumn = 'statusId';
    private idColumn = 'id';

    numberToCurrency = (value: any): string | null  => Currency.numberToCurrency(value);

    commonColumns = {
        coodeId: this.$t('table_column_coodeID') as string,
        name: this.$t('table_column_name') as string,
        status: this.$t('table_column_status') as string,
    }

    private allStagesColumns = {
        ...this.commonColumns,
        expectedBenefits: this.$t('table_column_expectedBenefits') as string,
        projectedBenefits: this.$t('table_column_projectedBenefits') as string,
        deliveredBenefits: this.$t('table_column_deliveredBenefits') as string,
        realizedBenefits: this.$t('table_column_realizedBenefitsCurrentYear') as string,
        scaledBenefits: this.$t('table_column_scaledBenefits') as string,
        deliveryEndDate: this.$t('table_column_deliveryEndDate') as string,
        expectedBudget: this.$t('table_column_expectedBudget') as string,
        deliveryOrganization: this.$t('table_column_deliveryOrganization') as string
    }

    private expectedColumns = {
        ...this.commonColumns,
        expectedBenefits: this.$t('table_column_expectedBenefits') as string,
        expectedBudget: this.$t('table_column_expectedBudget') as string,
        deliveryEndDate: this.$t('table_column_deliveryEndDate') as string,
        deliveryOrganization: this.$t('table_column_deliveryOrganization') as string
    }

    private inCreationColumns = {
        ...this.commonColumns,
        projectedBenefits: this.$t('table_column_projectedBenefits') as string,
        expectedBudget: this.$t('table_column_expectedBudget') as string,
        deliveryEndDate: this.$t('table_column_deliveryEndDate') as string,
        deliveryOrganization: this.$t('table_column_deliveryOrganization') as string
    }

    private deliveredColumns = {
        ...this.commonColumns,
        deliveredBenefits: this.$t('table_column_deliveredBenefits') as string,
        realizedBenefits: this.$t('table_column_realizedBenefitsCurrentYear') as string,
        scaledBenefits: this.$t('table_column_scaledBenefits') as string,
        expectedBudget: this.$t('table_column_expectedBudget') as string,
        deliveryEndDate: this.$t('table_column_deliveryEndDate') as string,
        deliveryOrganization: this.$t('table_column_deliveryOrganization') as string
    }

    private deliveryOrganizations: any = []

    async mounted(): Promise<void> {
        this.subview = this.$route.query.subview || SUBVIEW.ALL_STAGES;
        this.getFiltersSelection();
        await this.getData();

        if (!this.isInCreationViewSelected && this.$refs['pui-filter']) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs['pui-filter'].onAvailableWidthChanged(500)
        }
    }

    private formatDateParam(value: string | null): string{
        if (value === null) {
            return '';
        }
        const [date, time] = value.split('T');
        const [year, month, day] = date.split('-');
        return `${year}-${month}-${parseInt(day) + 1}T${time}`;
    }

    private get parametersList(): {[key: string]: any}{
        const parameters: {[key: string]: any } = {};
        if (this.filterByStartDate)
            parameters[FILTER_PARAMS.START_DATE] = new Date(this.formatDateParam(this.filterByStartDate));
        if (this.filterByEndDate)
            parameters[FILTER_PARAMS.END_DATE] = new Date(this.formatDateParam(this.filterByEndDate));
        parameters[FILTER_PARAMS.DELIVERY_ORG] = this.filterByDeliveryOrg;
        parameters[FILTER_PARAMS.STATUS] = this.filterByStatus;
        parameters[FILTER_PARAMS.REALIZED] = this.filterByRealized;
        parameters[FILTER_PARAMS.TERM] = this.$route.query.q ? String(this.$route.query.q) : '';
        switch(true) {
        case this.isAllStagesViewSelected:
            parameters[FILTER_PARAMS.STAGE] = '';
            break;
        case this.isExpectedViewSelected:
            parameters[FILTER_PARAMS.STAGE] = 0;
            break;
        case this.isInCreationViewSelected:
            parameters[FILTER_PARAMS.STAGE] = 1;
            break;
        case this.isDeliveredViewSelected:
            parameters[FILTER_PARAMS.STAGE] = 2;
            break;
        default:
            break;
        }
        return parameters;
    }

    private async getData(): Promise<void> {
        this.benefitsDataLoaded = false;
        this.statisticsDataLoaded = false;
        await this.$store.dispatch(actions.LOAD_BENEFITS_STATISTICS);
        if(this.$store.state.financial.benefitsStatistics.length !== 0) {
            this.statisticsDataLoaded = true;
        }

        await this.$store.dispatch(actions.LOAD_DELIVERY_TEAMS);
        this.deliveryOrganizations = this.$store.state.financial.deliveryTeams;

        await this.$store.dispatch(actions.LOAD_ALL_STAGES_BENEFITS_REPORTS, this.parametersList);
        if(this.$store.state.financial.benefitsReports.length !== 0) {
            this.allStagesBenefitsReports = this.$store.state.financial.benefitsReports;
            this.benefitsDataLoaded = true;
        }
        await this.$store.dispatch('dashboard/getStatuses');
    }

    private getFiltersSelection(): void {
        this.filterByRealized = benefitsFilterSelection.getRealized();
        this.filterByDeliveryOrg = benefitsFilterSelection.getDeliveryOrganizations();
        this.filterByStatus = benefitsFilterSelection.getStatuses();
        this.filterByStartDate = benefitsFilterSelection.getStartDate();
        this.filterByEndDate = benefitsFilterSelection.getEndDate();
    }

    private get deliveryOrganizationsFilters(): any[] {
        return this.deliveryOrganizations.map((item: DeliveryTeam) => ({
            value: item.id,
            displayName: item.name
        }));
    }

    private deliveryOrganizationName(deliveryId: number | undefined): any {
        if(deliveryId === undefined)
            return '';
        return this.deliveryOrganizations.find((item: DeliveryTeam) => item.id === deliveryId).name;
    }

    private belongsToBenefitsFilters(statusId: number | undefined): boolean {
        const statusesForBenefits = [Status.DeliveryApproved, Status.InPoC, Status.InMVP, Status.InMP, Status.InImplementation, Status.InUse];
        return statusesForBenefits.some(id => id === statusId);
    }

    private get statusFilters(): { displayName: string; value: number; phaseId: number }[] {
        let statusesForFilters: { displayName: string; value: number; phaseId: number }[] = []
        statusesForFilters = this.$store.state.dashboard.statuses.filter((status: IStatusDto) =>
            this.belongsToBenefitsFilters(status.id)
        ).map((status: IStatusDto) => ({
            displayName: status.name,
            value: status.id,
            phaseId: status.phaseId,
        }))

        return statusesForFilters
    }

    private filterValueChanged(e: Event): void {
        this.filterByDeliveryOrg = Object(e)[BENEFITS_FILTERS.DELIVERY_ORG] ?? [];
        this.filterByStatus = Object(e)[BENEFITS_FILTERS.STATUS] ?? [];
        const {end, start} =  Object(e)[BENEFITS_FILTERS.END_DATE] ?? Object({});
        this.filterByStartDate = start;
        this.filterByEndDate = end;

        if (this.filterByDeliveryOrg.length === 0 && this.filterByStatus.length === 0 &&
            this.filterByStartDate == null && this.filterByEndDate == null) {
            this.filterByRealized = false;
        }

        this.getData();
        this.setFiltersSelection();
    }

    private setFiltersSelection(): void {
        benefitsFilterSelection.setDeliveryOrganizations(this.filterByDeliveryOrg);
        benefitsFilterSelection.setStatuses(this.filterByStatus);
        benefitsFilterSelection.setStartDate(this.filterByStartDate);
        benefitsFilterSelection.setEndDate(this.filterByEndDate);
    }

    get allStagesBenefitsData(): any[] {
        return this.allStagesBenefitsReports.map((item: IFinancialBenefitsUseCaseDto) => ({
            [this.idColumn]: item.id,
            [this.statusIdColumn]: item.statusId,
            [this.allStagesColumns.coodeId]: item.coodeId,
            [this.allStagesColumns.name]: item.name,
            [this.allStagesColumns.status]: item.status,
            [this.allStagesColumns.expectedBenefits]:item.expectedBenefits,
            [this.allStagesColumns.projectedBenefits]: item.projectedBenefits,
            [this.allStagesColumns.deliveredBenefits]: item.deliveredBenefits,
            [this.allStagesColumns.realizedBenefits]: item.realizedBenefits,
            [this.allStagesColumns.scaledBenefits]: item.scaledBenefits,
            [this.allStagesColumns.deliveryEndDate]: this.formatDate(item.deliveryEndDate),
            [this.allStagesColumns.expectedBudget]: item.expectedBudget,
            [this.allStagesColumns.deliveryOrganization]: this.deliveryOrganizationName(item.deliveryOrganization)
        }))
    }

    private get allStagesVendorOptions(): any {
        return {
            options: {
                sortable: Object.values(this.allStagesColumns)
            },
            columns: Object.values(this.allStagesColumns),
            data: this.allStagesBenefitsData
        }
    }

    get expectedBenefitsData(): any[] {
        return this.allStagesBenefitsReports.map((item: IFinancialBenefitsUseCaseDto) => ({
            [this.idColumn]: item.id,
            [this.statusIdColumn]: item.statusId,
            [this.expectedColumns.coodeId]: item.coodeId,
            [this.expectedColumns.name]: item.name,
            [this.expectedColumns.status]: item.status,
            [this.expectedColumns.expectedBenefits]: item.expectedBenefits,
            [this.expectedColumns.expectedBudget]: item.expectedBudget,
            [this.expectedColumns.deliveryEndDate]: this.formatDate(item.deliveryEndDate),
            [this.expectedColumns.deliveryOrganization]: this.deliveryOrganizationName(item.deliveryOrganization)
        }))
    }

    private get expectedVendorOptions(): any {
        return {
            options: {
                sortable: Object.values(this.expectedColumns)
            },
            columns: Object.values(this.expectedColumns),
            data: this.expectedBenefitsData
        }
    }

    get inCreationBenefitsData(): any[] {
        return this.allStagesBenefitsReports.map((item: IFinancialBenefitsUseCaseDto) => ({
            [this.idColumn]: item.id,
            [this.statusIdColumn]: item.statusId,
            [this.inCreationColumns.coodeId]: item.coodeId,
            [this.inCreationColumns.name]: item.name,
            [this.inCreationColumns.status]: item.status,
            [this.inCreationColumns.projectedBenefits]: item.projectedBenefits,
            [this.inCreationColumns.expectedBudget]: item.expectedBudget,
            [this.inCreationColumns.deliveryEndDate]: this.formatDate(item.deliveryEndDate),
            [this.inCreationColumns.deliveryOrganization]: this.deliveryOrganizationName(item.deliveryOrganization)
        }))
    }

    private get inCreationVendorOptions(): any {
        return {
            options: {
                sortable: Object.values(this.inCreationColumns)
            },
            columns: Object.values(this.inCreationColumns),
            data: this.inCreationBenefitsData
        }
    }

    get deliveredBenefitsData(): any[] {
        return this.allStagesBenefitsReports.map((item: IFinancialBenefitsUseCaseDto) => ({
            [this.idColumn]: item.id,
            [this.statusIdColumn]: item.statusId,
            [this.deliveredColumns.coodeId]: item.coodeId,
            [this.deliveredColumns.name]: item.name,
            [this.deliveredColumns.status]: item.status,
            [this.deliveredColumns.deliveredBenefits]: item.deliveredBenefits,
            [this.deliveredColumns.realizedBenefits]: item.realizedBenefits,
            [this.deliveredColumns.scaledBenefits]:item.scaledBenefits,
            [this.deliveredColumns.expectedBudget]: item.expectedBudget,
            [this.deliveredColumns.deliveryEndDate]: this.formatDate(item.deliveryEndDate),
            [this.deliveredColumns.deliveryOrganization]: this.deliveryOrganizationName(item.deliveryOrganization)
        }))
    }
    get deliveredVendorOptions(): any{
        return {
            options: {
                sortable: Object.values(this.deliveredColumns)
            },
            columns: Object.values(this.deliveredColumns),
            data: this.deliveredBenefitsData
        }
    }

    private getMultiselectFilters(): {}[] {
        const deliveryOrganizationFilter = {
            name: BENEFITS_FILTERS.DELIVERY_ORG,
            displayName: this.$t('deliveryOrg'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('delivery'),
                options: this.deliveryOrganizationsFilters
            },
            selectedValues: {
                options: this.filterByDeliveryOrg
            },
            appliedValues: {
                options: this.filterByDeliveryOrg
            }
        };

        const statusFilter = {
            name: BENEFITS_FILTERS.STATUS,
            displayName: this.$t('byStatus'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Status',
                options: this.statusFilters
            },
            selectedValues: {
                options: this.filterByStatus
            },
            appliedValues: {
                options: this.filterByStatus
            }
        }

        const dateRangeFilter = {
            name: BENEFITS_FILTERS.END_DATE,
            displayName: this.$t('endDate'),
            type: 'daterange',
            isExpandable: true,
            isExpanded: true,
            config: {
                isRange: true,
                daysOnly: true,
                isStacked: true,
                label: {
                    start: 'From',
                    end: 'To'
                },
                selectableRange: {
                    start: '1990-01-01',
                    end: '2023-01-01'
                }
            },
            selectedValues: {
                start: this.filterByStartDate,
                end: this.filterByEndDate
            },
            appliedValues: {
                start: this.filterByStartDate,
                end: this.filterByEndDate
            }
        }

        const filtersList = [];

        filtersList.push(statusFilter);
        filtersList.push(deliveryOrganizationFilter);
        filtersList.push(dateRangeFilter);
        return filtersList;
    }

    get vendorOptionsForCurrentStage(): any {
        if (this.isAllStagesViewSelected) {
            return this.allStagesVendorOptions
        } else if (this.isExpectedViewSelected) {
            return this.expectedVendorOptions
        } else if (this.isInCreationViewSelected) {
            return this.inCreationVendorOptions
        }  else if (this.isDeliveredViewSelected) {
            return this.deliveredVendorOptions
        }
        return null
    }

    @Watch('$route.query.subview')
    async onSubviewChanged(to: any): Promise<void> {
        this.subview = to;
        if(to === SUBVIEW.ALL_STAGES)
            this.filterByRealized = false;
        await this.getData();
    }

    @Watch('$route.query.q')
    onSearchQueryChanged(): void {
        if (this.subview === SUBVIEW.ALL_STAGES || this.$route.query.q && this.$route.query.q.length > 0) {
            this.subview = SUBVIEW.ALL_STAGES;
            this.getData();
        }
    }

    @Watch('filterByRealized')
    onFilterChanged(filterByRealized: any): void {
        benefitsFilterSelection.setRealized(filterByRealized);
        this.getData();
    }

    get isAllStagesViewSelected(): boolean {
        return this.subview === SUBVIEW.ALL_STAGES;
    }

    get isExpectedViewSelected(): boolean {
        return this.subview === SUBVIEW.EXPECTED;
    }

    get isInCreationViewSelected(): boolean {
        return this.subview === SUBVIEW.IN_CREATION;
    }

    get isDeliveredViewSelected(): boolean {
        return this.subview === SUBVIEW.DELIVERED;
    }

    private async realizedToggleValueChanged(e: Event): Promise<void> {
        this.filterByRealized = (e as any) as boolean
        benefitsFilterSelection.setRealized(this.filterByRealized);
        await this.getData();
    }

    get benefitsStatistics(): any[] {
        const phaseValues = [];
        for(const phase of Object.values(PHASES)){
            const budgetPhase = this.$store.state.financial.benefitsStatistics[phase] || {};
            const budgetValue = budgetPhase[BUDGET_TYPES.VALUE_ADD] ?? EMPTY_CELL;
            phaseValues.push(budgetValue);
        }
        return phaseValues;
    }

    private getPhaseName(phaseIndex: any): string {
        if(phaseIndex === 0)
            return 'Expected';
        if(phaseIndex === 1)
            return 'In Creation';
        if(phaseIndex === 2)
            return 'Delivered';
        if(phaseIndex === 3)
            return 'Realized';
        return '';

    }

    get benefitsStatisticReportData(): any[] {
        return [
            {
                label: 'table_column_valueAdd',
                columns: this.benefitsStatistics
            }
        ]
    }

    get phases(): any[] {
        const {expected} = this.$store.state.financial.benefitsStatistics;
        const {inCreation} = this.$store.state.financial.benefitsStatistics;
        const {delivered} = this.$store.state.financial.benefitsStatistics;
        const {realized} = this.$store.state.financial.benefitsStatistics;
        return [expected,inCreation,delivered,realized];
    }

    getUrlForAppStoreApp(item: any): string {
        const url =new URL(process.env.VUE_APP_APP_STORE_UI_BASE_URL) ;
        if(url){
            url.searchParams.append('appId', item.useCaseId);
        }
        return `${url}`;
    }

    getUrlForUsecaseWithName(item: any): string {
        const id = item[this.idColumn];
        const name = item[this.commonColumns.name];
        const emptyValue = '-';

        if (id == null || id === emptyValue || name === null || name === emptyValue)
            return '#';

        return `#/ideas/edit/${id}?`;
    }

    formatDate(date: any): any {
        if (!moment(date, true).isValid()) return '-';

        return moment(date)
            .locale('en')
            .format('DD.MM.YYYY');
    }

    private isEmptyCell(cell: string): boolean{
        return cell === EMPTY_CELL;
    }

    private useCaseIsInUse(useCase: any): boolean {
        return useCase.statusId == Status.InUse || useCase.statusId == Status.InImplementation
    }

    private goTo(subview?: string): void {
        if(this.$route.query.subview !== subview){
            this.$router.push({
                params: {
                    area: 'financial',
                },
                query: {
                    view: this.view,
                    subview: this.subview
                },
            })
        }
    }

    private async filterByPhase(phase: any): Promise<void>{
        switch(phase){
        case PHASE_TABS.REALIZED:
            this.subview = SUBVIEW.DELIVERED;
            this.filterByRealized = true;
            this.goTo('delivered');
            break;
        case PHASE_TABS.EXPECTED:
            this.subview = SUBVIEW.EXPECTED;
            this.filterByRealized = false;
            this.goTo('expected');
            break;
        case PHASE_TABS.IN_CREATION:
            this.subview = SUBVIEW.IN_CREATION;
            this.filterByRealized = false;
            this.goTo('in-creation');
            break;
        case PHASE_TABS.DELIVERED:
            this.subview = SUBVIEW.DELIVERED;
            this.filterByRealized = false;
            this.goTo('delivered');
            break;
        default:
            break;
        }
    }
}
