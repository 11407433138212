export default class BenefitsFiltersSelection {
    private readonly realizedKey: string = 'filtersSelectionBenefits.realized';
    private readonly deliveryOrgKey: string = 'filtersSelectionBenefits.deliveryOrg';
    private readonly statusesKey: string = 'filtersSelectionBenefits.statuses';
    private readonly startDateKey: string = 'filtersSelectionBenefits.startDate';
    private readonly endDateKey: string = 'filtersSelectionBenefits.endDate';

    public setRealized(realized: boolean): void {
        localStorage.setItem(this.realizedKey, JSON.stringify(realized));
    }

    public getRealized(): boolean {
        const realizedResult = localStorage.getItem(this.realizedKey);
        if (!realizedResult) return false;
        return JSON.parse(realizedResult);
    }

    public setStartDate(startDate: string |null): void {
        if (startDate !== null) {
            localStorage.setItem(this.startDateKey, startDate);
            return;
        }
        localStorage.setItem(this.startDateKey, '');
    }

    public getStartDate(): string | null {
        return localStorage.getItem(this.startDateKey) || null;
    }

    public setEndDate(endDate: string | null): void {
        if (endDate !== null) {
            localStorage.setItem(this.endDateKey, endDate);
            return;
        }
        localStorage.setItem(this.endDateKey, '');
    }

    public getEndDate(): string | null{
        return localStorage.getItem(this.endDateKey) || null;
    }

    public setDeliveryOrganizations(deliveryOrganizations: Array<string>): void {
        localStorage.setItem(this.deliveryOrgKey, JSON.stringify(deliveryOrganizations));
    }

    public getDeliveryOrganizations(): Array<any> {
        const deliveryOrgResult = localStorage.getItem(this.deliveryOrgKey);
        if (!deliveryOrgResult) return [];
        return JSON.parse(deliveryOrgResult);
    }

    public setStatuses(statuses: Array<string>): void {
        localStorage.setItem(this.statusesKey, JSON.stringify(statuses));
    }

    public getStatuses(): Array<any> {
        const statusesResult = localStorage.getItem(this.statusesKey);
        if (!statusesResult) return [];
        return JSON.parse(statusesResult);
    }
}
