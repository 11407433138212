












import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'page-not-found',
})
export default class Home extends Vue {
    pageNotFound = this.$t('pageNotFound');

    get isLoading(): boolean {
        return this.$store.getters.isLoading;
    }
}
