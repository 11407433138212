













































import { Component, Watch, Vue } from 'vue-property-decorator';
import FinancialBenefits from './components/financial-benefits.vue';
import FinancialCosts from './components/financial-costs.vue';

const VIEW = {
    COSTS: 'costs',
    BENEFITS: 'benefits',
}

const SUBVIEW = {
    ALL_STAGES: 'all-stages',
    PROOF_OF_CONCEPT: 'proof-of-concept',
    IN_DELIVERY: 'in-delivery',
    IN_USE: 'in-use'
}

@Component({
    name: 'financial',
    components: {
        FinancialBenefits,
        FinancialCosts
    }
})

export default class Financial extends Vue {

    private view: any = VIEW.COSTS;
    private subview: any = SUBVIEW.ALL_STAGES;

    private get my(): boolean {
        return 'my' === this.$route.params.area;
    }

    async mounted(): Promise<void> {
        this.view = this.$route.query.view || VIEW.COSTS;
        this.subview = this.$route.query.subview || SUBVIEW.ALL_STAGES;
    }

    @Watch('$route.query.view')
    onViewChanged(to: any): void {
        this.view = to;
    }

    @Watch('$route.query.subview')
    onSubviewChanged(to: any): void {
        this.subview = to;
    }

    get isCostsView(): boolean {
        return this.view === VIEW.COSTS;
    }

    get isBenefitsView(): boolean {
        return this.view === VIEW.BENEFITS;
    }
}
