


















































































































import moment from 'moment';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IdeaYearlyCostListResponse } from '@/service-proxies/service-proxies.g';
import { actions } from '@/store/types';
import Currency from '@/utils/helpers/currency';

@Component({
    name: 'financial-evaluation-yearly'
})
export default class FinancialEvaluationYearly extends Vue {
    @Prop()
    private showPastData!: boolean;

    $store: any;

    private financialDataLoaded = false;
    private yearlyReports: IdeaYearlyCostListResponse | null = null;
    private totalBudgetValue = null;

    numberToCurrency = (value: any): string | null  => Currency.numberToCurrency(value);


    private async mounted(): Promise<void> {
        await this.getData();
    }

    @Watch('showPastData')
    async onshowPastDataChanged(): Promise<void> {
        await this.getData();
    }

    private async getData(): Promise<void> {
        this.financialDataLoaded = false

        const ideaId = this.$route.params.id
        await this.$store.dispatch(actions.LOAD_YEARLY_EVALUATION_REPORTS, {
            ideaId
        });
        this.yearlyReports = this.$store.state.financial.financialEvaluationReportsYearly;
        this.totalBudgetValue = this.$store.state.financial.financialEvaluationReportsYearly.totalBudget;
        this.financialDataLoaded = true;
        this.transmitTotalBudgetValue();
    }

    formatDate(date: string): string {
        return moment(date).format('MMM');
    }

    private transmitTotalBudgetValue (): void {
        this.$emit('transmitYearlyBudget',  this.totalBudgetValue)
    }
}
