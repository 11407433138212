




























































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { IdeaYearlyAndQuarterlyCostDto } from '@/service-proxies/service-proxies.g';
import { actions } from '@/store/types';
import Currency from '@/utils/helpers/currency';

@Component({
    name: 'financial-evaluation-quarterly'
})
export default class FinancialEvaluationQuarterly extends Vue {
    @Prop()
    private showPastData!: boolean;

    $store: any;

    private financialDataLoaded = false;
    private quarterlyReports: IdeaYearlyAndQuarterlyCostDto[] = [];
    private totalBudgetValue: string | null = null;

    numberToCurrency = (value: any): string | null  => Currency.numberToCurrency(value);

    private async mounted(): Promise<void> {
        await this.getData()
    }

    @Watch('showPastData')
    async onshowPastDataChanged(): Promise<void> {
        await this.getData();
    }

    private async getData(): Promise<void> {
        this.financialDataLoaded = false

        const ideaId = this.$route.params.id
        await this.$store.dispatch(actions.LOAD_QUARTERLY_EVALUATION_REPORTS, {
            ideaId
        });
        this.quarterlyReports = this.$store.state.financial.financialEvaluationReportsQuarterly.yearlyAndQuarterlyCosts;
        this.totalBudgetValue = this.numberToCurrency(this.$store.state.financial.financialEvaluationReportsQuarterly.totalBudget);
        this.transmitTotalBudgetValue();
        this.financialDataLoaded = true;
    }

    private transmitTotalBudgetValue (): void {
        this.$emit('transmitQuarterlyBudget',  this.totalBudgetValue);
    }
}
