


























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import FinancialEvaluationQuarterly from './components/financial-evaluation-quarterly.vue'
import FinancialEvaluationYearly from './components/financial-evaluation-yearly.vue'
import { actions } from '@/store/types';
import { IdeaMonthlyCostDto, IdeaMonthlyCostListResponse } from '@/service-proxies/service-proxies.g';
import moment from 'moment';
import Currency from '@/utils/helpers/currency';

const VIEW: {[key: string]: string} = {
    MONTHLY: 'monthly',
    YEARLY: 'yearly',
    QUARTERLY: 'quarterly'
}

@Component({
    name: 'financialEvaluation',
    components: {
        FinancialEvaluationQuarterly,
        FinancialEvaluationYearly
    }
})

export default class FinancialEvaluation extends Vue {

    $store: any;
    private monthlyReports: IdeaMonthlyCostListResponse | null = null;
    private monthlyCostsLoaded = false;
    private readonly DEFAULT_VIEW =  VIEW.MONTHLY;
    private view: any = this.DEFAULT_VIEW;
    private monthlyCosts: IdeaMonthlyCostDto[] = [];
    private yearlyTotalBudget = null;
    private quarterlyTotalBudget = null;
    private projectStartDate: Date|undefined = new Date();
    private projectEndDate: Date|undefined = new Date();
    private monthlyCostsAvailable = false;
    numberToCurrency = (value: any): string | null  => Currency.numberToCurrency(value);

    @Watch('$route.query.view')
    onViewChanged(view: string): void {
        if (!view)
        {
            const {query} = this.$route;
            this.view = this.DEFAULT_VIEW;
            this.$router.replace({query: {...query, view: this.view}});
        }
    }

    private get isMonthlyView(): boolean {
        return this.view === VIEW.MONTHLY
    }

    private get isYearlyView(): boolean {
        return this.view === VIEW.YEARLY
    }

    private get isQuarterlyView(): boolean {
        return this.view === VIEW.QUARTERLY
    }

    private handleSelectOptionChange(optionValue: string): void{
        if (optionValue !== this.$route.query.view) {
            const {query} = this.$route;
            this.view = optionValue;
            this.$router.replace({query: {...query, view: optionValue}});
        }
    }

    mounted(): void {
        this.getData();
        this.view = VIEW.MONTHLY;
    }

    updated(): void {
        this.view = this.$route.query.view
    }

    private async getData(): Promise<void> {
        this.monthlyCostsLoaded = false;
        this.monthlyCostsAvailable = false;
        const ideaId = this.$route.params.id
        await this.$store.dispatch(actions.LOAD_MONTHLY_EVALUATION_REPORTS, {
            ideaId
        });
        this.monthlyReports = this.$store.state.financial.financialEvaluationReportsMonthly;
        this.monthlyCosts = this.$store.state.financial.financialEvaluationReportsMonthly.monthlyCosts;

        this.projectStartDate = this.monthlyReports?.projectDetails?.startDate;
        this.projectEndDate = this.monthlyReports?.projectDetails?.endDate;
        this.monthlyCostsLoaded = true;
        this.monthlyCostsAvailable = this.monthlyCosts?.length > 0;
    }

    private get viewOptions(): any[] {

        return [
            {
                label: 'Monthly',
                value: VIEW.MONTHLY
            },
            {
                label: 'Quarterly',
                value: VIEW.QUARTERLY
            },
            {
                label: 'Yearly',
                value: VIEW.YEARLY
            }
        ];
    }

    private getTotalBudgetForYearly(value: any): void{
        this.yearlyTotalBudget = value;
    }

    private getTotalBudgetForQuarterly(value: any): void{
        this.quarterlyTotalBudget = value;
    }

    get totalBudgetByView(): any{
        if(this.isYearlyView)
            return this.yearlyTotalBudget;
        return this.quarterlyTotalBudget;
    }

    formatDate(date: any): string {
        return date !== undefined ? moment(date).format('YYYY-MM-DD') : '';
    }
}

