



































































































































































































































































import { Watch, Vue, Component } from 'vue-property-decorator';

import { AdamCardConfig } from 'adam.ui-core/dist/src/lib/components/card';

import { PHASE } from '@/components/status/status.vue';
import { DeliveryTeam } from '@/models';

import moment from 'moment';

import {
    IUseCaseAllStagesDto,
    IUseCaseProofOfConceptDto,
    IUseCaseInDeliveryDto,
    IUseCaseInUseDto,
    Status,
    DeliveryTypeListDto
} from '@/service-proxies/service-proxies.g';

import { actions } from '@/store/types';
import FinancialFiltersSelection from '@/utils/local-storage/financial-filters-selection';
import Currency from '@/utils/helpers/currency';
const filtersSelection = new FinancialFiltersSelection();

const FinancialFilteringKeys = {
    Delivery: 'delivery',
    Team: 'team',
    OverBudget: 'over-budget',
}

const VIEW = {
    COSTS: 'costs',
    BENEFITS: 'benefits',
}

const SUBVIEW = {
    ALL_STAGES: 'all-stages',
    PROOF_OF_CONCEPT: 'proof-of-concept',
    IN_DELIVERY: 'in-delivery',
    IN_USE: 'in-use'
}

const EMPTY_CELL = 'EMPTY_CELL';

const BUDGET_TYPES = {
    BUDGET: 'budget',
    ACTUAL_AND_COMMITED: 'actualAndCommited',
    REMAINING: 'remaining'
};

const PHASES = {
    TOTAL_COSTS: 'stagesTotalCosts',
    POC: 'proofOfConceptStage',
    IN_DELIVERY: 'inDeliveryStage',
    IN_USE: 'inUseStage'
}

const BUDGETS = {
    TOTAL_BUDGET: 'totalDevelopmentBudget',
    NOT_ALLOCATED_BUDGET: 'notAllocatedBudget'
}

const PHASE_TABS = {
    ALL_STAGES: 'Total Development',
    IN_POC: 'In PoC',
    IN_DELIVERY: 'In Delivery',
    DELIVERED: 'In Use'
}

@Component({
    name: 'financial-costs'
})

export default class FinancialCosts extends Vue {
    $store: any;

    private filterByDeliveryType: string[] = [];
    private filterByDeliveryTeam: string[] = [];

    private filterByOverBudget = false;

    private financialDataLoaded = false
    private statisticsDataLoaded = false

    private allStagesFinancialReports: IUseCaseAllStagesDto[] = []
    private proofOfConceptFinancialReports: IUseCaseProofOfConceptDto[] = []
    private inDeliveryFinancialReports: IUseCaseInDeliveryDto[] = []
    private inUseFinancialReports: IUseCaseInUseDto[] = []

    private view: any = VIEW.COSTS;
    private subview: any = SUBVIEW.ALL_STAGES;

    private MY_IDEAS = undefined;
    private STATUS_DRAFT = undefined // 1;

    numberToCurrency = (value: any): string | null  => Currency.numberToCurrency(value);

    endDateValidationStatusIds = [
        Status.InPoC,
        Status.InMVP,
        Status.InMP
    ];

    commonColumns = {
        coodeId: this.$t('table_column_coodeID') as string,
        name: this.$t('table_column_name') as string,
    }

    private allStagesColumns = {
        ...this.commonColumns,
        status: this.$t('table_column_status') as string,
        budget: this.$t('table_column_budget') as string,
        actualCommitted: this.$t('table_column_actualCommitted') as string,
        remaining: this.$t('table_column_remaining') as string,
        deliveryStartDate: this.$t('table_column_deliveryStartDate') as string,
        deliveryEndDate: this.$t('table_column_deliveryEndDate') as string,
        deliveryType: this.$t('table_column_deliveryType') as string,
        wbs: this.$t('table_column_WBS') as string,
    }

    private proofOfConceptColumns = {
        ...this.commonColumns,
        deliveryStartDate: this.$t('table_column_deliveryStartDate') as string,
        deliveryEndDate: this.$t('table_column_deliveryEndDate') as string,
        wbs: this.$t('table_column_WBS') as string,
        deliveryType: this.$t('table_column_deliveryType') as string
    }

    private inDeliveryColumns = {
        ...this.commonColumns,
        status: this.$t('table_column_status') as string,
        budget: this.$t('table_column_budget') as string,
        actual: this.$t('table_column_actual') as string,
        committed: this.$t('table_column_committed') as string,
        remaining: this.$t('table_column_remaining') as string,
        deliveryStartDate: this.$t('table_column_deliveryStartDate') as string,
        deliveryEndDate: this.$t('table_column_deliveryEndDate') as string,
        deliveryType: this.$t('table_column_deliveryType') as string,
        wbs: this.$t('table_column_WBS') as string
    }

    private inUseColumns = {
        ...this.commonColumns,
        status: this.$t('table_column_status') as string,
        businessOwner: this.$t('table_column_businessOwner') as string,
        // projectedBenefits: this.$t('table_column_projectedBenefits') as string,
        // realizedBenefits: this.$t('table_column_realizedBenefits') as string,
        deliveryEndDate: this.$t('table_column_deliveryEndDate') as string,
        actual: this.$t('table_column_actual') as string
    }

    private statusIdColumn = 'statusId'
    private idColumn = 'id'
    private useCaseId = 'useCaseId'

    private deliveryTypes: any = []
    private deliveryTeams: any = []

    private cardConfig: AdamCardConfig = {
        hasAction: true,
    }

    private get my(): boolean {
        return 'my' === this.$route.params.area;
    }

    private get benefitsDisabled(): boolean {
        return false
    }

    private get allStagesVendorOptions(): any {
        return {
            options: {
                sortable: Object.values(this.allStagesColumns)
            },
            columns: Object.values(this.allStagesColumns),
            data: this.allStagesFinancialData
        }
    }

    private get proofOfConceptVendorOptions(): any {
        return {
            options: {
                sortable: Object.values(this.proofOfConceptColumns)
            },
            columns: Object.values(this.proofOfConceptColumns),
            data: this.proofOfConceptFinancialData
        }
    }

    private get inDeliveryVendorOptions(): any {
        return {
            options: {
                sortable: Object.values(this.inDeliveryColumns)
            },
            columns: Object.values(this.inDeliveryColumns),
            data: this.inDeliveryFinancialData
        }
    }

    private get inUseVendorOptions(): any {
        return {
            options: {
                sortable: Object.values(this.inUseColumns)
            },
            columns: Object.values(this.inUseColumns),
            data: this.inUseFinancialData
        }
    }

    get vendorOptionsForCurrentStage(): any {
        if (this.isAllStagesViewSelected) {
            return this.allStagesVendorOptions
        } else if (this.isProofOfConceptViewSelected) {
            return this.proofOfConceptVendorOptions
        } else if (this.isInDeliveryViewSelected) {
            return this.inDeliveryVendorOptions
        } else if (this.isInUseViewSelected) {
            return this.inUseVendorOptions
        }

        return null
    }

    get allStagesFinancialData(): any[] {
        return this.allStagesFinancialReports.map((item: IUseCaseAllStagesDto) => ({
            [this.idColumn]: item.id,
            [this.statusIdColumn]: item.statusId,
            [this.allStagesColumns.coodeId]: this.validateValue(item.coodeId),
            [this.allStagesColumns.name]: this.validateValue(item.name),
            [this.allStagesColumns.status]: this.validateValue(item.status?.replace(/In/g, 'In ')),
            [this.allStagesColumns.budget]: item.plannedBudget,
            [this.allStagesColumns.actualCommitted]: item.actualAndCommited,
            [this.allStagesColumns.remaining]: item.remainingBudget,
            [this.allStagesColumns.deliveryStartDate]: item.deliveryStartDate,
            [this.allStagesColumns.deliveryEndDate]: item.deliveryEndDate,
            [this.allStagesColumns.deliveryType]: this.validateValue(item.deliveryType),
            [this.allStagesColumns.wbs]: this.validateValue(item.wbs),
        }))
    }

    get statisticReportData(): any[] {
        return [
            {
                label: 'table_column_budget',
                columns: this.financialStatistics[BUDGET_TYPES.BUDGET]
            },
            {
                label: 'table_column_actualCommitted',
                columns: this.financialStatistics[BUDGET_TYPES.ACTUAL_AND_COMMITED],
            },
            {
                label: 'table_column_remaining',
                columns: this.financialStatistics[BUDGET_TYPES.REMAINING]
            }
        ]
    }

    get proofOfConceptFinancialData(): any[] {
        return this.proofOfConceptFinancialReports.map((item: IUseCaseProofOfConceptDto) => ({
            [this.idColumn]: item.id,
            [this.statusIdColumn]: item.statusId,
            [this.proofOfConceptColumns.coodeId]: this.validateValue(item.coodeId),
            [this.proofOfConceptColumns.name]: this.validateValue(item.name),
            [this.proofOfConceptColumns.deliveryStartDate]: item.deliveryStartDate,
            [this.proofOfConceptColumns.deliveryEndDate]: item.deliveryEndDate,
            [this.proofOfConceptColumns.wbs]: this.validateValue(item.wbs),
            [this.proofOfConceptColumns.deliveryType]: this.validateValue(item.deliveryType)
        }))
    }

    get inDeliveryFinancialData(): any[] {
        return this.inDeliveryFinancialReports.map((item: IUseCaseInDeliveryDto) => ({
            [this.idColumn]: item.id,
            [this.statusIdColumn]: item.statusId,
            [this.inDeliveryColumns.coodeId]: this.validateValue(item.coodeId),
            [this.inDeliveryColumns.name]: this.validateValue(item.name),
            [this.allStagesColumns.status]: this.validateValue(item.status?.replace(/In/g, 'In ')),
            [this.inDeliveryColumns.budget]: item.plannedBudget,
            [this.inDeliveryColumns.actual]: item.actualSpent,
            [this.inDeliveryColumns.committed]: item.commitedToSpend,
            [this.inDeliveryColumns.remaining]: item.remainingBudget,
            [this.inDeliveryColumns.deliveryStartDate]: item.deliveryStartDate,
            [this.inDeliveryColumns.deliveryEndDate]: item.deliveryEndDate,
            [this.inDeliveryColumns.deliveryType]: this.validateValue(item.deliveryType),
            [this.inDeliveryColumns.wbs]: this.validateValue(item.wbs),
        }))
    }

    get inUseFinancialData(): any[] {
        return this.inUseFinancialReports.map((item: IUseCaseInUseDto) => ({
            [this.idColumn]: item.id,
            [this.statusIdColumn]: item.statusId,
            [this.inUseColumns.coodeId]: this.validateValue(item.coodeId),
            [this.inUseColumns.name]: this.validateValue(item.name),
            [this.allStagesColumns.status]: this.validateValue(item.status?.replace(/In/g, 'In ')),
            [this.inUseColumns.businessOwner]: this.validateValue(item.businessOwner),
            [this.inUseColumns.deliveryEndDate]: item.deliveryEndDate,
            [this.inUseColumns.actual]: item.actualSpent,
            [this.useCaseId]: item.useCaseId
        }))
    }

    get allPhases(): any[] {
        const {stagesTotalCosts} = this.$store.state.financial.statistics;
        return [stagesTotalCosts,this.phases];
    }

    get phases(): any[] {
        const {proofOfConceptStage} = this.$store.state.financial.statistics;
        const {inDeliveryStage} = this.$store.state.financial.statistics;
        const {inUseStage} = this.$store.state.financial.statistics;
        return [proofOfConceptStage,inDeliveryStage,inUseStage];
    }

    get financialStatistics(): {[key: string]: string[]} {
        const financialStatistics: {[key: string]: string[] } = {};
        for(const budgetType of Object.values(BUDGET_TYPES)){
            const phaseValues = [];
            for(const phase of Object.values(PHASES)){
                const budgetPhase = this.$store.state.financial.statistics[phase] || {};
                const budgetValue = budgetPhase[budgetType] ?? EMPTY_CELL;
                phaseValues.push(budgetValue);
            }
            financialStatistics[budgetType] = phaseValues;
        }
        return financialStatistics;
    }

    private useCaseIsInUse(useCase: any): boolean {
        return useCase.statusId == Status.InUse || useCase.statusId == Status.InImplementation
    }

    private isEmptyCell(cell: string): boolean{
        return cell === EMPTY_CELL;
    }

    private goTo(subview?: string): void {

        if(this.$route.query.subview !== subview){
            this.$router.push({
                params: {
                    area: 'financial',
                },
                query: {
                    view: this.view,
                    subview: this.subview
                },
            })
        }
    }

    private async filterByPhase(phase: any): Promise<void>{
        switch(phase){
        case PHASE_TABS.ALL_STAGES:
            this.subview = SUBVIEW.ALL_STAGES;
            this.goTo('all-stages');
            break;
        case PHASE_TABS.IN_POC:
            this.subview = SUBVIEW.PROOF_OF_CONCEPT;
            this.goTo('proof-of-concept');
            break;
        case PHASE_TABS.IN_DELIVERY:
            this.subview = SUBVIEW.IN_DELIVERY;
            this.goTo('in-delivery');
            break;
        case PHASE_TABS.DELIVERED:
            this.subview = SUBVIEW.IN_USE;
            this.goTo('in-use');
            break;
        default:
            break;
        }
    }

    get plannedBudget(): {[key: string]: string} {
        const {statistics} = this.$store.state.financial;
        return Object.values(BUDGETS).reduce((acc, budget) => {
            const budgetLabel = statistics[budget] !== undefined ? (this.numberToCurrency(statistics[budget]) ??  '') + this.$t('euroSymbol') : '';
            return {
                ...acc,
                [budget]: budgetLabel
            };
        }, {})
    }

    get totalBudget(): string {
        return this.plannedBudget[BUDGETS.TOTAL_BUDGET];
    }

    get notAllocatedBudget(): string {
        return this.plannedBudget[BUDGETS.NOT_ALLOCATED_BUDGET];
    }

    getUrlForUsecaseWithName(item: any): string {
        const id = item[this.idColumn];
        const name = item[this.commonColumns.name];
        const emptyValue = '-';

        if (id == null || id === emptyValue || name === null || name === emptyValue)
            return '#';

        return `#/financial/use-cases/${id}?usecaseName=${name}`;
    }

    getUrlForAppStoreApp(item: any): string {
        const url =new URL(process.env.VUE_APP_APP_STORE_UI_BASE_URL) ;
        if(url){
            url.searchParams.append('appId', item.useCaseId);
        }
        return `${url}`;
    }

    getTotalUseCases(): number {
        return this.$store.state.financial.statistics.stagesTotalCosts.stageCount;
    }

    getPhaseColor(phase: any): string {
        if(phase.stageName === PHASE_TABS.IN_POC)
            return (PHASE)['In PoC'];
        if(phase.stageName === PHASE_TABS.IN_DELIVERY)
            return (PHASE)['In Delivery'];
        return phase.stageName ? (PHASE as any)[phase.stageName] : '#ccc'
    }

    formatDate(date: any): any {
        if (!moment(date, true).isValid()) return '-';

        return moment(date)
            .locale('en')
            .format('DD.MM.YYYY');
    }

    private validateValue(value: any): any {
        if (value == null) return '-';

        return value;
    }

    get formattedLastUpdate(): any{
        return 'Last update ' + moment(this.$store.state.financial.statistics.lastUpdated).locale('en').format('DD/MM/YYYY HH:MM A');
    }

    private async getData(area = this.$route.params.area): Promise<void> {
        this.financialDataLoaded = false
        this.statisticsDataLoaded = false
        await this.$store.dispatch(actions.LOAD_STATISTICS, {
            // unused
            onlyCurrentUser: area === 'my',
        });
        if(this.$store.state.financial.statistics.length !== 0) {
            this.statisticsDataLoaded = true
        }

        await this.$store.dispatch(actions.LOAD_DELIVERY_TYPES);
        this.deliveryTypes = this.$store.state.financial.deliveryTypes;

        await this.$store.dispatch(actions.LOAD_DELIVERY_TEAMS);
        this.deliveryTeams = this.$store.state.financial.deliveryTeams;

        const filterParams = {
            onlyOverBudget: this.filterByOverBudget,
            deliveryTypes: this.filterByDeliveryType,
            deliveryTeams: this.filterByDeliveryTeam,
            searchTerm: this.$route.query.q ? String(this.$route.query.q) : '',
        }
        await this.loadDataForCurrentView(filterParams)

        this.financialDataLoaded = true
    }

    private async loadDataForCurrentView(filterParams: any): Promise<void> {
        switch(true) {
        case this.isAllStagesViewSelected:
            await this.$store.dispatch(actions.LOAD_ALL_STAGES_REPORTS, filterParams);
            this.allStagesFinancialReports = this.$store.state.financial.allStagesFinancialReports
            break;
        case this.isProofOfConceptViewSelected:
            await this.$store.dispatch(actions.LOAD_PROOF_OF_CONCEPT_REPORTS, filterParams);
            this.proofOfConceptFinancialReports = this.$store.state.financial.proofOfConceptFinancialReports
            break;
        case this.isInDeliveryViewSelected:
            await this.$store.dispatch(actions.LOAD_IN_DELIVERY_REPORTS, filterParams);
            this.inDeliveryFinancialReports = this.$store.state.financial.inDeliveryFinancialReports
            break;
        case this.isInUseViewSelected:
            await this.$store.dispatch(actions.LOAD_IN_USE_REPORTS, filterParams);
            this.inUseFinancialReports = this.$store.state.financial.inUseFinancialReports
            break;
        default:
            break;
        }
    }

    async mounted(): Promise<void> {
        this.subview = this.$route.query.subview || SUBVIEW.ALL_STAGES;

        this.getFiltersSelection();
        await this.getData();

        // TODO: remove this at some point
        // HACK TO MAKE FILTERS WORK
        if (!this.isInUseViewSelected && this.$refs['pui-filter']) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs['pui-filter'].onAvailableWidthChanged(380)
        }
    }

    private getFiltersSelection(): void {
        this.filterByDeliveryType = filtersSelection.getDeliveryTypes();
        this.filterByDeliveryTeam = filtersSelection.getDeliveryTeams();
        this.filterByOverBudget = filtersSelection.getOverBudget();
    }

    @Watch('$route.query.subview')
    async onSubviewChanged(to: any): Promise<void> {
        this.subview = to;

        await this.getData();
    }

    @Watch('$route.query.q')
    onSearchQueryChanged(): void {
        if (this.subview === SUBVIEW.ALL_STAGES || this.$route.query.q && this.$route.query.q.length > 0) {
            this.subview = SUBVIEW.ALL_STAGES;
            this.getData();
        }
    }

    @Watch('filterByOverBudget')
    async onFilterChanged(): Promise<void> {
        await this.getData();
    }

    get isAllStagesViewSelected(): boolean {
        return this.subview === SUBVIEW.ALL_STAGES
    }

    get isProofOfConceptViewSelected(): boolean {
        return this.subview === SUBVIEW.PROOF_OF_CONCEPT
    }

    get isInDeliveryViewSelected(): boolean {
        return this.subview === SUBVIEW.IN_DELIVERY
    }

    get isInUseViewSelected(): boolean {
        return this.subview === SUBVIEW.IN_USE
    }

    private get deliveryTypeFilters(): any[] {
        return this.deliveryTypes.map((item: DeliveryTypeListDto) => ({
            value: item.id,
            displayName: item.title
        }));
    }

    private get deliveryTeamFilters(): any[] {
        return this.deliveryTeams.map((item: DeliveryTeam) => ({
            value: item.id,
            displayName: item.name
        }));
    }

    private filterValueChanged(e: Event): void {
        this.filterByDeliveryType = Object(e)[FinancialFilteringKeys.Delivery] ? Object(e)[FinancialFilteringKeys.Delivery] : []
        this.filterByDeliveryTeam = Object(e)[FinancialFilteringKeys.Team] ? Object(e)[FinancialFilteringKeys.Team] : []

        if (this.filterByDeliveryType.length == 0 && this.filterByDeliveryTeam.length == 0) {
            this.filterByOverBudget = false
        }

        this.getData();
        this.setFiltersSelection();
    }

    private setFiltersSelection(): void {
        filtersSelection.setDeliveryTypes(this.filterByDeliveryType);
        filtersSelection.setDeliveryTeams(this.filterByDeliveryTeam);
    }

    private async onlyBudgetToggleValueChanged(e: Event): Promise<void> {
        this.filterByOverBudget = (e as any) as boolean
        filtersSelection.setOverBudget(this.filterByOverBudget);
        await this.getData();
    }

    private getMultiselectFilters(): {}[] {
        const deliveryTypeFilters = {
            name: FinancialFilteringKeys.Delivery,
            displayName: this.$t('delivery'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('delivery'),
                options: this.deliveryTypeFilters
            },
            selectedValues: {
                options: this.filterByDeliveryType
            },
            appliedValues: {
                options: this.filterByDeliveryType
            }
        };

        const deliveryTeamFilter = {
            name: FinancialFilteringKeys.Team,
            displayName: this.$t('organization'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: this.$t('organization'),
                options: this.deliveryTeamFilters
            },
            selectedValues: {
                options: this.filterByDeliveryTeam
            },
            appliedValues: {
                options: this.filterByDeliveryTeam
            }
        };

        const filtersList = [];

        filtersList.push(deliveryTypeFilters);
        filtersList.push(deliveryTeamFilter);

        return filtersList;
    }
}
